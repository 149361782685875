import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import helper from "../utils/helper";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage"));
const ForgotPage = lazy(() => import("../pages/ForgotPage"));
const ResetPage = lazy(() => import("../pages/ResetPage"));

const HomePage = lazy(() => import("../pages/HomePage"));
const SeekPage = lazy(() => import("../pages/SeekPage"));
const SeekDetailPage = lazy(() => import("../pages/SeekDetailPage"));
const CompanyDetailPage = lazy(() => import("../pages/CompanyDetailPage"));
const CompanyPage = lazy(() => import("../pages/CompanyPage"));
const JobPage = lazy(() => import("../pages/JobPage"));
const ResumePage = lazy(() => import("../pages/ResumePage"));
const ResumeDetailPage = lazy(() => import("../pages/ResumeDetailPage"));
const CreateContractPage = lazy(() => import("../pages/CreateContractPage"));
const ContractDetailPage = lazy(() => import("../pages/ContractDetailPage"));
const PayrollSettingPage = lazy(() => import("../pages/PayrollSettingPage"));
const PayrollPage = lazy(() => import("../pages/PayrollPage"));
const PayrollDetailPage = lazy(() => import("../pages/PayrollDetailPage"));
const ApplymentPage = lazy(() => import("../pages/ApplymentPage"));
const DashboardPage = lazy(() => import("../pages/DashboardPage"));
const BlankNotAccess = lazy(() => import("../components/Blank/BlankNotAccess"));
const MasterPage = lazy(() => import("../pages/MasterPage"));

const PageLoader = () => (
  <div id="loader">
    <div className="loadingio-spinner-rolling-3kjtqyynjid">
      <div className="ldio-ucwibazh2i9">
        <div></div>
      </div>
    </div>
  </div>
);
const PublicRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ title, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        helper.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function Router(props) {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route exact path="/home" render={(props) => <HomePage {...props} />} />
        <Route exact path="/jobs" render={(props) => <SeekPage {...props} />} />
        <Route
          exact
          path="/job-detail"
          render={(props) => <SeekDetailPage {...props} />}
        />
        <Route
          exact
          path="/company-detail"
          render={(props) => <CompanyDetailPage {...props} />}
        />
        <PublicRoute path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute path="/registration">
          <RegisterPage />
        </PublicRoute>
        <PublicRoute path="/forgot">
          <ForgotPage />
        </PublicRoute>
        <Route
          exact
          path="/reset/:token?"
          render={(props) =>
            helper.isAuthenticated() ? (
              <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: props.location },
                }}
              />
            ) : props.match.params.token ? (
              <ResetPage token={props.match.params.token} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
        <PrivateRoute path="/dashboard">
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute path="/opt-company">
          <CompanyPage />
        </PrivateRoute>
        <PrivateRoute path="/opt-job">
          <JobPage />
        </PrivateRoute>
        <PrivateRoute path="/resume">
          <ResumePage />
        </PrivateRoute>
        <PrivateRoute path="/apply">
          <ApplymentPage />
        </PrivateRoute>
        <PrivateRoute path="/job-candidate-detail">
          <ResumeDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/create-contract">
          <CreateContractPage />
        </PrivateRoute>
        <PrivateRoute path="/contract-detail">
          <ContractDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/payroll-setting">
          <PayrollSettingPage />
        </PrivateRoute>
        <PrivateRoute path="/payroll-opt">
          <PayrollPage />
        </PrivateRoute>
        <PrivateRoute path="/payroll-export">
          <PayrollDetailPage />
        </PrivateRoute>
        <PrivateRoute path={"*"}>
          {helper.isAuthenticated() ? <MasterPage /> : <BlankNotAccess />}
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
export default withRouter(Router);
